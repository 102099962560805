import React, {useEffect, useState} from 'react';
import {appUtil} from "../constant/appUtil";
import { useHistory } from "react-router-dom";
import {appConst, appSettings} from "../constant/appConst";
function ListItem({property}) {
  const history = useHistory();
  function onClick(){
    history.push("/property-View/"+property.id);
  }
  const [statusClass,setStatusClass] = useState();
  useEffect(()=> {
    if(property.status_id === appConst.status.available) setStatusClass("available")
    if(property.status_id === appConst.status.sold || property.status_id === appConst.status.signed) setStatusClass("sold")
    if(property.status_id === appConst.status.reserved) setStatusClass("reserved")
    if(property.status_id === appConst.status.not_released) setStatusClass("not_released")
  },[])

  return (

  <tr onClick={() => onClick()}>
    <td width="150">{statusClass && <span className={"property-status-icon " + statusClass}/>}</td>
    <td><strong>{property.code}</strong></td>
    <td>{property.net_area} {appSettings.areaUnit}</td>
    <td>{property.gross_area} {appSettings.areaUnit}</td>
    <td>{property.terrace_area} {appSettings.areaUnit}</td>
    <td>{property.pool_area} {appSettings.areaUnit}</td>

  </tr>

  );
}

export default ListItem;
import React, {useEffect, useState} from 'react';
import '../../css/Modal.css';
import ButtonWithLoading from "../ButtonWithLoading";
import {appUtil} from "../../constant/appUtil";
import {appConst} from "../../constant/appConst";
import {apiConst} from "../../constant/apiConst";

function ModalEditNote(props) {
  
  const modalClass = "edit-note";
  const [fields, setFields] = useState({
    note: props.note.note || ""
  });
  const [errors, setErrors] = useState({note : ""});
  const [isLoading,setIsLoading] = useState(false);

  function hideModal(){

    appConst.closeModal(modalClass);
    props.clearActive();

  }

  function handleValidation(){
    let errors = {};
    let formIsValid = true;

    if(!fields["note"])
    {
      formIsValid = false;
      errors["note"] = "Please type note about this client";
    }


    setErrors(errors)
    return formIsValid;
  }


  function onSave(){

    if(handleValidation())
    {
      setIsLoading(true);

      apiConst.clientNoteUpdate(props.client.id,fields).then(result => {
        setIsLoading(false);
        if(result.response)
        {
          props.onConfirm("update",result.client);
          hideModal();
        }
      })

    }

  }


  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }

  return (

    <div className={"modal " + modalClass + " fade"} id="testModal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">{props.modalTitle}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h3>{props.title}</h3>
            <div className="form-element">
              <textarea type="text" className={errors["note"] ? "form-control is-invalid" : "form-control"} id="note" placeholder="Client Note" defaultValue={props.note.note || ""} onChange={(e) => handleChange("note",e)} />
              {
                errors["note"] && <div className="invalid-feedback">
                  {errors["note"]}
                </div>
              }
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="default-button secondary" data-dismiss="modal" onClick={hideModal}>{props.cancelText}</button>
            <ButtonWithLoading className={"default-button "} onClick={onSave} label={props.confirmText} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>

  );
  
}

export default ModalEditNote;
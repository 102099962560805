import React, {useState, useContext, useEffect} from 'react';
import {
    Link, withRouter,
} from "react-router-dom";
import '../css/FilterSubMenu.css';
import FilterContext from "./context/FilterContext.js";
import AppDataContext from "./context/AppDataContext";
import {appUtil} from "../constant/appUtil";
import {appSettings} from "../constant/appConst";
import FilterTag from "./FilterTag";

function FilterSubMenu(props) {

    const { filter, setFilter } = useContext(FilterContext);
    const { appData, setAppData } = useContext(AppDataContext);
    const [filterStatusTag,setFilterStatusTag] = useState(false)
    const priceUnit = appSettings.priceFilter.unit;
    const [sliderValueMin,setSliderValueMin] = useState(filter.priceMin/priceUnit);
    const [sliderValueMax,setSliderValueMax] = useState(filter.priceMax/priceUnit);



    useEffect(() => {

    },[sliderValueMin,sliderValueMax])

    function ChangeStatus(status){

        let newFilter = {...filter};
        newFilter.status = status;
        setFilter(newFilter);

    }

    function ChangeFloorType(floorType){

        let newFilter = {...filter};
        const index = newFilter.floorType.indexOf(floorType);
        if (index > -1) {
            newFilter.floorType.splice(index, 1);
        }
        else {
            newFilter.floorType.push(floorType);
        }

        setFilter(newFilter);

    }

    function ChangeBuildingType(buildingType){

        let newFilter = {...filter};
        newFilter.buildingType = buildingType;
        setFilter(newFilter);

    }


    function ChangePrice(type,price){

        let newFilter = {...filter};
        if(type === "min") newFilter.priceMin = price;
        if(type === "max") newFilter.priceMax = price;
        setFilter(newFilter);

    }


    useEffect(() =>{
        if(appData.isFilterModeActive)
        updateFilteredProperties()

    },[filter])



    function updateFilteredProperties()
    {
        if(appData.loaded) {
            let newAppData = {...appData};
            newAppData.filteredProperties = appData.properties.filter(function(p) {
                if(filter.floorType.length)
                {
                    if( (!filter.floorType.includes(p.room_type_id))) return false;
                }
                if(filter.status)
                {
                    if(p.status_id !== parseInt(filter.status)) return false;
                }
                if(filter.buildingType)
                {
                    if(p.building_type_id !== parseInt(filter.buildingType)) return false;
                }

                if(filter.priceMin && filter.priceMax)
                {

                    if(p.price < filter.priceMin || p.price > filter.priceMax) return false;

                }
                return true;
            });

            setAppData(newAppData);
        }

    }

    function onClickButtonClear(){

        document.getElementById("filter-floor-type").reset();
        document.getElementById("filter-status").reset();
        document.getElementById("filter-building-type").reset();
        setSliderValueMin(appSettings.priceFilter.min / priceUnit)
        setSliderValueMax(appSettings.priceFilter.max / priceUnit)
        setFilter({
            floorType: [],
            buildingType : false,
            priceMin: appSettings.priceFilter.min,
            priceMax: appSettings.priceFilter.max,
            status: false
        });

        let newAppData = {...appData};
        newAppData.filteredProperties = newAppData.properties;
        setTimeout(()=> {setAppData(newAppData);},100)



    }

    function getLabel(type,id) {
        if(type === "status") {
            let label = "";
            appData.statuses.forEach(status => {
                if(status.id === id) label = status.label
            })
            return label;

        }
        if(type === "floorType") {
            let label = "";
            appData.roomTypes.forEach(room => {
                if(room.id === id) label = room.label
            })
            return label;

        }

        if(type === "buildingType") {
            let label = "";
            appData.buildingTypes.forEach(building => {
                if(building.id === id) label = building.label
            })
            return label;

        }

    }



    function onClickBuildingTypesToggle(){
        let filterForm = document.querySelector("#filter-building-type")
        filterForm.classList.toggle("d-none")
        let filterToggleBtn = document.querySelector(".filter-building-type-toggle-btn")
        filterToggleBtn.querySelector(".icon").classList.toggle("icon-up")
        filterToggleBtn.querySelector(".icon").classList.toggle("icon-down")
    }





  return (

    <div className="filter-menu side-menu">

        <div className="inner">
            <button className={"close-button"} onClick={props.onClickClose}>
                <div></div>
                <div></div>
            </button>
            <div className={"title"}>
                <h2>FILTER</h2>
            </div>
            <h3>Building Types <button onClick={onClickBuildingTypesToggle} className="filter-building-type-toggle-btn"><span className="icon icon-up"></span></button></h3>
            <section>

                <form className="floor-type" id="filter-building-type">
                    <div className="row no-gutters">
                        {appData.buildingTypes && appData.buildingTypes.map(building => (
                            <div className="col-3 " key={building.id}>
                                <div className="form-group" >
                                    <label className="check-container">{building.label}
                                        <input type="radio" id={building.label} name="building" value={building.id} checked={filter.buildingType === building.id} onChange={()=> {ChangeBuildingType(building.id);}} />
                                        <span className="checkmark" />
                                    </label>
                                </div>

                            </div>

                        ))}
                    </div>
                </form>

            </section>
            <h3>Floor Types</h3>
            <section>

                <form className="floor-type" id="filter-floor-type">
                    <div className="row no-gutters">
                    {appData.roomTypes && appData.roomTypes.map(room => (
                        <div className="col-6" key={room.id}>
                            <div className="form-group" >
                                <label className="check-container">{room.label === "1 Bed TH Corner" ? "1 Bed TH Cor" : room.label}
                                    <input type="checkbox" id={room.label} name="room" value={room.id} checked={filter.floorType.includes(room.id)} onChange={()=> {ChangeFloorType(room.id);}} />
                                    <span className="checkmark" />
                                </label>
                            </div>

                        </div>

                    ))}
                    </div>
                </form>

            </section>

            {appSettings.showPrices &&
                <div>
                    <h3>Price Range</h3>
                    <section className="price-range">

                        <div>
                            <div className="price-display">
                                <span>{appUtil.formatPrice(sliderValueMin * priceUnit)}</span> - <span>{appUtil.formatPrice(sliderValueMax * priceUnit)}</span>
                            </div>
                            <div className="price-slider-container mt-2">
                                <input
                                    type="range"
                                    id="filter-price-range-min"
                                    name="price-range-min"
                                    min={appSettings.priceFilter.min / appSettings.priceFilter.unit}
                                    max={appSettings.priceFilter.max / appSettings.priceFilter.unit}
                                    value={sliderValueMin}
                                    onChange = {(e) => {

                                        let value = parseInt(e.target.value);

                                        if (value < sliderValueMax - 2) {
                                            setSliderValueMin(value);
                                            ChangePrice("min",value* priceUnit);
                                        }

                                    }}
                                />

                                <input
                                    type="range"
                                    id="filter-price-range-max"
                                    name="price-range-max"
                                    min={appSettings.priceFilter.min / appSettings.priceFilter.unit}
                                    max={appSettings.priceFilter.max / appSettings.priceFilter.unit}
                                    value={sliderValueMax}
                                    onChange = {(e) => {

                                        let value = parseInt(e.target.value);

                                        if (value > sliderValueMin + 2) {
                                            setSliderValueMax(value);
                                            ChangePrice("max",value * priceUnit);
                                        }

                                    }}
                                />
                            </div>

                        </div>

                    </section>
                </div>

            }
            <h3>Status</h3>
            <section>
                <form className="status" id="filter-status">
                    <div className="row no-gutters">
                    {appData.statuses && appData.statuses.map(status => (
                        <div className="col-6" key={status.id}>
                        <div className="form-group" >
                            <label className="check-container">{status.label}
                                <input type="radio" id={"status_"+status.label.toLowerCase()} name="status" value={status.id} checked={filter.status === status.id} onChange={()=> {ChangeStatus(status.id);}} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        </div>
                    ))}
                    </div>
                </form>
            </section>




            <h3>View</h3>
            <section>
                <Link to="/map-view" >
                    <button className={props.location.pathname === "/map-view" ? "button-view map active" :"button-view map"  }>
                        <span></span>
                        Map
                    </button>
                </Link>
                <Link to="/list-view" >
                    <button className={props.location.pathname === "/list-view" ? "button-view list active" :"button-view list"  }>
                        <span></span>
                        List
                    </button>
                </Link>
            </section>
            <h3>Filter</h3>
            <section className={"filter-tag-container"}>
                {filter.status && <FilterTag type={"status"} text={getLabel("status",filter.status)}/> }
                {filter.buildingType && <FilterTag type={"buildingType"} text={getLabel("buildingType",filter.buildingType)}/> }
                {filter.floorType.length > 0 && filter.floorType.map((item,i) => (
                    <FilterTag type={"floorType"} key={i} text={getLabel("floorType",item)} floorId={item}/>
                    ))}
                {filter.priceMin !== appSettings.priceFilter.min && <FilterTag type={"priceMin"} text={"Min:"+appUtil.formatPrice(filter.priceMin)} stateChanger={setSliderValueMin}/> }
                {filter.priceMax !== appSettings.priceFilter.max && <FilterTag type={"priceMax"} text={"Max:"+appUtil.formatPrice(filter.priceMax)} stateChanger={setSliderValueMax}/> }
            </section>
            
        </div>


        <button className="default-button button-clear" onClick={onClickButtonClear}>CLEAR</button>



     </div>

  );
}

export default withRouter(FilterSubMenu);
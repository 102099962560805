export const propertyConst = {
    slider : {
        "1st_floor_studio_b" : [
            {source : require("../img/property_slider/1st_floor_studio_b/i1.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_b/i2.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_b/i3.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_b/i4.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_b/i5.jpg")},
        ],

        "1st_floor_studio_c" : [
            {source : require("../img/property_slider/1st_floor_studio_c/i1.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_c/i2.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_c/i3.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_c/i4.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_c/i5.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_c/i6.jpg")},
            {source : require("../img/property_slider/1st_floor_studio_c/i7.jpg")},
        ],

        "gf_1_bed" : [
            {source : require("../img/property_slider/gf_1_bed/i1.jpg")},
            {source : require("../img/property_slider/gf_1_bed/i2.jpg")},
            {source : require("../img/property_slider/gf_1_bed/i3.jpg")},
            {source : require("../img/property_slider/gf_1_bed/i4.jpg")},
            {source : require("../img/property_slider/gf_1_bed/i5.jpg")},
            {source : require("../img/property_slider/gf_1_bed/i6.jpg")},
            {source : require("../img/property_slider/gf_1_bed/i7.jpg")},

        ],

        "gf_2_bed" : [
            {source : require("../img/property_slider/gf_2_bed/i1.jpg")},
            {source : require("../img/property_slider/gf_2_bed/i2.jpg")},
            {source : require("../img/property_slider/gf_2_bed/i3.jpg")},
            {source : require("../img/property_slider/gf_2_bed/i4.jpg")},
            {source : require("../img/property_slider/gf_2_bed/i5.jpg")},
            {source : require("../img/property_slider/gf_2_bed/i6.jpg")},
            {source : require("../img/property_slider/gf_2_bed/i7.jpg")},

        ],

        "gf_studio_a" : [
            {source : require("../img/property_slider/gf_studio_a/i1.jpg")},
            {source : require("../img/property_slider/gf_studio_a/i2.jpg")},
            {source : require("../img/property_slider/gf_studio_a/i3.jpg")},
            {source : require("../img/property_slider/gf_studio_a/i4.jpg")},
            {source : require("../img/property_slider/gf_studio_a/i5.jpg")},
            {source : require("../img/property_slider/gf_studio_a/i6.jpg")},
        ],

        "gf_studio_b" : [
            {source : require("../img/property_slider/gf_studio_b/i1.jpg")},
            {source : require("../img/property_slider/gf_studio_b/i2.jpg")},
            {source : require("../img/property_slider/gf_studio_b/i3.jpg")},
            {source : require("../img/property_slider/gf_studio_b/i4.jpg")},
            {source : require("../img/property_slider/gf_studio_b/i5.jpg")},
        ],

        "gf_studio_c" : [
            {source : require("../img/property_slider/gf_studio_c/i1.jpg")},
            {source : require("../img/property_slider/gf_studio_c/i2.jpg")},
            {source : require("../img/property_slider/gf_studio_c/i3.jpg")},
            {source : require("../img/property_slider/gf_studio_c/i4.jpg")},
            {source : require("../img/property_slider/gf_studio_c/i5.jpg")},
            {source : require("../img/property_slider/gf_studio_c/i6.jpg")},
            {source : require("../img/property_slider/gf_studio_c/i7.jpg")},
            {source : require("../img/property_slider/gf_studio_c/i8.jpg")},
        ],

        "island_villa" : [
            {source : require("../img/property_slider/island_villa/i1.jpg")},
            {source : require("../img/property_slider/island_villa/i2.jpg")},
            {source : require("../img/property_slider/island_villa/i3.jpg")},
            {source : require("../img/property_slider/island_villa/i4.jpg")},
            {source : require("../img/property_slider/island_villa/i5.jpg")},
            {source : require("../img/property_slider/island_villa/i6.jpg")},
            {source : require("../img/property_slider/island_villa/i7.jpg")},
            {source : require("../img/property_slider/island_villa/i8.jpg")},
            {source : require("../img/property_slider/island_villa/i9.jpg")},
            {source : require("../img/property_slider/island_villa/i10.jpg")},
        ],

        "townhouse" : [
            {source : require("../img/property_slider/townhouse/i1.jpg")},
            {source : require("../img/property_slider/townhouse/i2.jpg")},
            {source : require("../img/property_slider/townhouse/i3.jpg")},
            {source : require("../img/property_slider/townhouse/i4.jpg")},
            {source : require("../img/property_slider/townhouse/i5.jpg")},
            {source : require("../img/property_slider/townhouse/i6.jpg")},
            {source : require("../img/property_slider/townhouse/i7.jpg")},
        ],

    },
    thumb : {
        "1st_floor_studio_b" : [
            {source : require("../img/property_thumbs/1st_floor_studio_b/i1.jpg")},
        ],

        "1st_floor_studio_c" : [
            {source : require("../img/property_thumbs/1st_floor_studio_c/i1.jpg")},
        ],

        "gf_1_bed" : [
            {source : require("../img/property_thumbs/gf_1_bed/i1.jpg")},
        ],

        "gf_studio_a" : [
            {source : require("../img/property_thumbs/gf_studio_a/i1.jpg")},
        ],

        "gf_studio_b" : [
            {source : require("../img/property_thumbs/gf_studio_b/i1.jpg")},
        ],

        "gf_studio_c" : [
            {source : require("../img/property_thumbs/gf_studio_c/i1.jpg")},
        ],

        "island_villa" : [
            {source : require("../img/property_thumbs/island_villa/i1.jpg")},
        ],

        "townhouse" : [
            {source : require("../img/property_thumbs/townhouse/i1.jpg")},
        ],

    },
    floorplan_slider : {

        "a1" : [{source : require("../img/property_floorplan/a/a1.jpg")}],
        "a2" : [{source : require("../img/property_floorplan/a/a2.jpg")}],
        "a3" : [{source : require("../img/property_floorplan/a/a3.jpg")}],
        "a4" : [{source : require("../img/property_floorplan/a/a4.jpg")}],
        "a5" : [{source : require("../img/property_floorplan/a/a5.jpg")}],
        "a6" : [{source : require("../img/property_floorplan/a/a6.jpg")}],
        "a7" : [{source : require("../img/property_floorplan/a/a7.jpg")}],
        "a8" : [{source : require("../img/property_floorplan/a/a8.jpg")}],
        "a9" : [{source : require("../img/property_floorplan/a/a9.jpg")}],
        "a10" : [{source : require("../img/property_floorplan/a/a10.jpg")}],
        "a11" : [{source : require("../img/property_floorplan/a/a11.jpg")}],
        "a12" : [{source : require("../img/property_floorplan/a/a12.jpg")}],
        "a13" : [{source : require("../img/property_floorplan/a/a13.jpg")}],
        "a14" : [{source : require("../img/property_floorplan/a/a14.jpg")}],
        "a15" : [{source : require("../img/property_floorplan/a/a15.jpg")}],
        "a16" : [{source : require("../img/property_floorplan/a/a16.jpg")}],
        "a17" : [{source : require("../img/property_floorplan/a/a17.jpg")}],
        "a18" : [{source : require("../img/property_floorplan/a/a18.jpg")}],
        "a19" : [{source : require("../img/property_floorplan/a/a19.jpg")}],
        "a20" : [{source : require("../img/property_floorplan/a/a20.jpg")}],

        "b1" : [{source : require("../img/property_floorplan/b/b1.jpg")}],
        "b2" : [{source : require("../img/property_floorplan/b/b2.jpg")}],
        "b3" : [{source : require("../img/property_floorplan/b/b3.jpg")}],
        "b4a" : [{source : require("../img/property_floorplan/b/b4a.jpg")}],
        "b4b" : [{source : require("../img/property_floorplan/b/b4b.jpg")}],
        "b5" : [{source : require("../img/property_floorplan/b/b5.jpg")}],
        "b6" : [{source : require("../img/property_floorplan/b/b6.jpg")}],
        "b7" : [{source : require("../img/property_floorplan/b/b7.jpg")}],
        "b8" : [{source : require("../img/property_floorplan/b/b8.jpg")}],
        "b9" : [{source : require("../img/property_floorplan/b/b9.jpg")}],
        "b10" : [{source : require("../img/property_floorplan/b/b10.jpg")}],
        "b11" : [{source : require("../img/property_floorplan/b/b11.jpg")}],
        "b12" : [{source : require("../img/property_floorplan/b/b12.jpg")}],
        "b13" : [{source : require("../img/property_floorplan/b/b13.jpg")}],
        "b14" : [{source : require("../img/property_floorplan/b/b14.jpg")}],
        "b15" : [{source : require("../img/property_floorplan/b/b15.jpg")}],
        "b16" : [{source : require("../img/property_floorplan/b/b16.jpg")}],
        "b17" : [{source : require("../img/property_floorplan/b/b17.jpg")}],

        "c1" : [{source : require("../img/property_floorplan/c/c1.jpg")}],
        "c2" : [{source : require("../img/property_floorplan/c/c2.jpg")}],
        "c3" : [{source : require("../img/property_floorplan/c/c3.jpg")}],
        "c4a" : [{source : require("../img/property_floorplan/c/c4a.jpg")}],
        "c4b" : [{source : require("../img/property_floorplan/c/c4b.jpg")}],
        "c5" : [{source : require("../img/property_floorplan/c/c5.jpg")}],
        "c6" : [{source : require("../img/property_floorplan/c/c6.jpg")}],
        "c7" : [{source : require("../img/property_floorplan/c/c7.jpg")}],
        "c8" : [{source : require("../img/property_floorplan/c/c8.jpg")}],
        "c9" : [{source : require("../img/property_floorplan/c/c9.jpg")}],
        "c10" : [{source : require("../img/property_floorplan/c/c10.jpg")}],
        "c11" : [{source : require("../img/property_floorplan/c/c11.jpg")}],
        "c12" : [{source : require("../img/property_floorplan/c/c12.jpg")}],
        "c13" : [{source : require("../img/property_floorplan/c/c13.jpg")}],
        "c14" : [{source : require("../img/property_floorplan/c/c14.jpg")}],
        "c15" : [{source : require("../img/property_floorplan/c/c15.jpg")}],

        "d2" : [{source : require("../img/property_floorplan/d/d1.jpg")}],
        "d3" : [{source : require("../img/property_floorplan/d/d1.jpg")}],
        "d4" : [{source : require("../img/property_floorplan/d/d1.jpg")}],
        "d5" : [{source : require("../img/property_floorplan/d/d1.jpg")}],
        "d6" : [{source : require("../img/property_floorplan/d/d1.jpg")}],
        "d7" : [{source : require("../img/property_floorplan/d/d1.jpg")}],

        "e1" : [{source : require("../img/property_floorplan/e/e1.jpg")}],
        "e2" : [{source : require("../img/property_floorplan/e/e2.jpg")}],
        "e3" : [{source : require("../img/property_floorplan/e/e3.jpg")}],
        "e4" : [{source : require("../img/property_floorplan/e/e4.jpg")}],
        "e5" : [{source : require("../img/property_floorplan/e/e5.jpg")}],
        "e6" : [{source : require("../img/property_floorplan/e/e6.jpg")}],
        "e7" : [{source : require("../img/property_floorplan/e/e7.jpg")}],
        "e8" : [{source : require("../img/property_floorplan/e/e8.jpg")}],
        "e9" : [{source : require("../img/property_floorplan/e/e9.jpg")}],
        "e10" : [{source : require("../img/property_floorplan/e/e10.jpg")}],
        "e11" : [{source : require("../img/property_floorplan/e/e11.jpg")}],
        "e12" : [{source : require("../img/property_floorplan/e/e12.jpg")}],
        "e13" : [{source : require("../img/property_floorplan/e/e13.jpg")}],
        "e14" : [{source : require("../img/property_floorplan/e/e14.jpg")}],
        "e15" : [{source : require("../img/property_floorplan/e/e15.jpg")}],
        "e16" : [{source : require("../img/property_floorplan/e/e16.jpg")}],
        "e17" : [{source : require("../img/property_floorplan/e/e17.jpg")}],
        "e18" : [{source : require("../img/property_floorplan/e/e18.jpg")}],
        "e19" : [{source : require("../img/property_floorplan/e/e19.jpg")}],
        "e20" : [{source : require("../img/property_floorplan/e/e20.jpg")}],

        "f1" : [{source : require("../img/property_floorplan/f/f1.jpg")}],
        "f2" : [{source : require("../img/property_floorplan/f/f2.jpg")}],
        "f3" : [{source : require("../img/property_floorplan/f/f3.jpg")}],
        "f4" : [{source : require("../img/property_floorplan/f/f4.jpg")}],
        "f5" : [{source : require("../img/property_floorplan/f/f5.jpg")}],
        "f6" : [{source : require("../img/property_floorplan/f/f6.jpg")}],
        "f7" : [{source : require("../img/property_floorplan/f/f7.jpg")}],
        "f8" : [{source : require("../img/property_floorplan/f/f8.jpg")}],
        "f9" : [{source : require("../img/property_floorplan/f/f9.jpg")}],
        "f10" : [{source : require("../img/property_floorplan/f/f10.jpg")}],
        "f11" : [{source : require("../img/property_floorplan/f/f11.jpg")}],
        "f12" : [{source : require("../img/property_floorplan/f/f12.jpg")}],
        "f13" : [{source : require("../img/property_floorplan/f/f13.jpg")}],
        "f14" : [{source : require("../img/property_floorplan/f/f14.jpg")}],
        "f15" : [{source : require("../img/property_floorplan/f/f15.jpg")}],
        "f16" : [{source : require("../img/property_floorplan/f/f16.jpg")}],
        "f17" : [{source : require("../img/property_floorplan/f/f17.jpg")}],
        "f18" : [{source : require("../img/property_floorplan/f/f18.jpg")}],
        "f19" : [{source : require("../img/property_floorplan/f/f19.jpg")}],
        "f20" : [{source : require("../img/property_floorplan/f/f20.jpg")}],

        "g1" : [{source : require("../img/property_floorplan/g/g1.jpg")}],
        "g2" : [{source : require("../img/property_floorplan/g/g2.jpg")}],
        "g3" : [{source : require("../img/property_floorplan/g/g3.jpg")}],
        "g4" : [{source : require("../img/property_floorplan/g/g4.jpg")}],
        "g5" : [{source : require("../img/property_floorplan/g/g5.jpg")}],
        "g6" : [{source : require("../img/property_floorplan/g/g6.jpg")}],
        "g7" : [{source : require("../img/property_floorplan/g/g7.jpg")}],
        "g8" : [{source : require("../img/property_floorplan/g/g8.jpg")}],
        "g9" : [{source : require("../img/property_floorplan/g/g9.jpg")}],
        "g10" : [{source : require("../img/property_floorplan/g/g10.jpg")}],
        "g11" : [{source : require("../img/property_floorplan/g/g11.jpg")}],
        "g12" : [{source : require("../img/property_floorplan/g/g12.jpg")}],
        "g13" : [{source : require("../img/property_floorplan/g/g13.jpg")}],
        "g14" : [{source : require("../img/property_floorplan/g/g14.jpg")}],
        "g15" : [{source : require("../img/property_floorplan/g/g15.jpg")}],
        "g16" : [{source : require("../img/property_floorplan/g/g16.jpg")}],

        "h1" : [{source : require("../img/property_floorplan/f/f1.jpg")}],
        "h2" : [{source : require("../img/property_floorplan/f/f2.jpg")}],
        "h3" : [{source : require("../img/property_floorplan/f/f3.jpg")}],
        "h4" : [{source : require("../img/property_floorplan/f/f4.jpg")}],
        "h5" : [{source : require("../img/property_floorplan/f/f5.jpg")}],
        "h6" : [{source : require("../img/property_floorplan/f/f6.jpg")}],
        "h7" : [{source : require("../img/property_floorplan/f/f7.jpg")}],
        "h8" : [{source : require("../img/property_floorplan/f/f8.jpg")}],
        "h9" : [{source : require("../img/property_floorplan/f/f9.jpg")}],
        "h10" : [{source : require("../img/property_floorplan/f/f10.jpg")}],
        "h11" : [{source : require("../img/property_floorplan/f/f11.jpg")}],
        "h12" : [{source : require("../img/property_floorplan/f/f12.jpg")}],
        "h13" : [{source : require("../img/property_floorplan/f/f13.jpg")}],
        "h14" : [{source : require("../img/property_floorplan/f/f14.jpg")}],
        "h15" : [{source : require("../img/property_floorplan/f/f15.jpg")}],
        "h16" : [{source : require("../img/property_floorplan/f/f16.jpg")}],
        "h17" : [{source : require("../img/property_floorplan/f/f17.jpg")}],
        "h18" : [{source : require("../img/property_floorplan/f/f18.jpg")}],
        "h19" : [{source : require("../img/property_floorplan/f/f19.jpg")}],
        "h20" : [{source : require("../img/property_floorplan/f/f20.jpg")}],

        "i1" : [{source : require("../img/property_floorplan/i/i1.jpg")}],
        "i2" : [{source : require("../img/property_floorplan/i/i1.jpg")}],
        "i3" : [{source : require("../img/property_floorplan/i/i1.jpg")}],

        "j1" : [{source : require("../img/property_floorplan/j/j1.jpg")}],
        "j2" : [{source : require("../img/property_floorplan/j/j2.jpg")}],
        "j3" : [{source : require("../img/property_floorplan/j/j3.jpg")}],
        "j4" : [{source : require("../img/property_floorplan/j/j4.jpg")}],
        "j5" : [{source : require("../img/property_floorplan/j/j5.jpg")}],
        "j6" : [{source : require("../img/property_floorplan/j/j6.jpg")}],
        "j7" : [{source : require("../img/property_floorplan/j/j7.jpg")}],
        "j8" : [{source : require("../img/property_floorplan/j/j8.jpg")}],
        "j9" : [{source : require("../img/property_floorplan/j/j9.jpg")}],
        "j10" : [{source : require("../img/property_floorplan/j/j10.jpg")}],
        "j11" : [{source : require("../img/property_floorplan/j/j11.jpg")}],
        "j12" : [{source : require("../img/property_floorplan/j/j12.jpg")}],
        "j13" : [{source : require("../img/property_floorplan/j/j13.jpg")}],
        "j14" : [{source : require("../img/property_floorplan/j/j14.jpg")}],
        "j15" : [{source : require("../img/property_floorplan/j/j15.jpg")}],
        "j16" : [{source : require("../img/property_floorplan/j/j16.jpg")}],
        "j17" : [{source : require("../img/property_floorplan/j/j17.jpg")}],
        "j18" : [{source : require("../img/property_floorplan/j/j18.jpg")}],
        "j19" : [{source : require("../img/property_floorplan/j/j19.jpg")}],
        "j20" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "j21" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "j22" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "j23" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "j24" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "j25" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "j26" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "j27" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "j28" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "j29" : [{source : require("../img/property_floorplan/m/m1.jpg")}],

        "k1" : [{source : require("../img/property_floorplan/k/k1.jpg")}],
        "k2" : [{source : require("../img/property_floorplan/k/k2.jpg")}],
        "k3" : [{source : require("../img/property_floorplan/k/k3.jpg")}],
        "k4" : [{source : require("../img/property_floorplan/k/k4.jpg")}],
        "k5" : [{source : require("../img/property_floorplan/k/k5.jpg")}],
        "k6" : [{source : require("../img/property_floorplan/k/k6.jpg")}],
        "k7" : [{source : require("../img/property_floorplan/k/k7.jpg")}],
        "k8" : [{source : require("../img/property_floorplan/k/k8.jpg")}],
        "k9" : [{source : require("../img/property_floorplan/k/k9.jpg")}],
        "k10" : [{source : require("../img/property_floorplan/k/k10.jpg")}],
        "k11" : [{source : require("../img/property_floorplan/k/k11.jpg")}],
        "k12" : [{source : require("../img/property_floorplan/k/k12.jpg")}],
        "k13" : [{source : require("../img/property_floorplan/k/k13.jpg")}],
        "k14" : [{source : require("../img/property_floorplan/k/k14.jpg")}],
        "k15" : [{source : require("../img/property_floorplan/k/k15.jpg")}],
        "k16" : [{source : require("../img/property_floorplan/k/k16.jpg")}],
        "k17" : [{source : require("../img/property_floorplan/k/k17.jpg")}],
        "k18" : [{source : require("../img/property_floorplan/k/k18.jpg")}],
        "k19" : [{source : require("../img/property_floorplan/k/k19.jpg")}],

        "l1" : [{source : require("../img/property_floorplan/l/l1.jpg")}],
        "l2" : [{source : require("../img/property_floorplan/l/l2.jpg")}],
        "l3" : [{source : require("../img/property_floorplan/l/l3.jpg")}],
        "l4" : [{source : require("../img/property_floorplan/l/l4.jpg")}],
        "l5" : [{source : require("../img/property_floorplan/l/l5.jpg")}],
        "l6" : [{source : require("../img/property_floorplan/l/l6.jpg")}],
        "l7" : [{source : require("../img/property_floorplan/l/l7.jpg")}],
        "l8" : [{source : require("../img/property_floorplan/l/l8.jpg")}],
        "l9" : [{source : require("../img/property_floorplan/l/l9.jpg")}],
        "l10" : [{source : require("../img/property_floorplan/l/l10.jpg")}],
        "l11" : [{source : require("../img/property_floorplan/l/l11.jpg")}],
        "l12" : [{source : require("../img/property_floorplan/l/l12.jpg")}],
        "l13" : [{source : require("../img/property_floorplan/l/l13.jpg")}],
        "l14" : [{source : require("../img/property_floorplan/l/l14.jpg")}],
        "l15" : [{source : require("../img/property_floorplan/l/l15.jpg")}],
        "l16" : [{source : require("../img/property_floorplan/l/l16.jpg")}],
        "l17" : [{source : require("../img/property_floorplan/l/l17.jpg")}],
        "l18" : [{source : require("../img/property_floorplan/l/l18.jpg")}],
        "l19" : [{source : require("../img/property_floorplan/l/l19.jpg")}],

        "m1" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "m2" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "m3" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "m4" : [{source : require("../img/property_floorplan/m/m1.jpg")}],
        "m5" : [{source : require("../img/property_floorplan/m/m1.jpg")}],


    },

    vr360 : {
        "1st_floor_studio_b" : "https://kuula.co/share/collection/7JZl1?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1/",
        "1st_floor_studio_c" : "https://kuula.co/share/collection/7JZlG?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "gf_1_bed" : "https://kuula.co/share/collection/7JZlN?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "gf_2_bed" : "https://kuula.co/share/collection/7JZlN?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "gf_studio_a" : "https://kuula.co/share/collection/7JZl6?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "gf_studio_b" : "https://kuula.co/share/collection/7JZlw?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "gf_studio_c" : "https://kuula.co/share/collection/7JZly?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "island_villa" : "https://kuula.co/share/collection/7JbTQ?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",
        "townhouse" : "https://kuula.co/share/5lkhF/collection/7JZP5?logo=-1&info=0&fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1",

    }
}